.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.modal-link {
  color: #000;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  width: 100%;
  background-color: #eee;
  transform: scale(1);
  /* Initial scale value */
  transition: transform 0.3s ease-in-out;
  /* Transition property for smooth scaling */
}

.modal-link span {
  margin-left: 10px;
}

.modal-link:hover {
  background-color: #5a67d8;
  color: #fff;
  transform: scale(1.1);
  /* Scaled value on hover */
}


@keyframes changeColor {
  0% {
    color: rgb(71, 33, 33);
  }

  25% {
    color: rgb(173, 96, 24);
  }

  50% {
    color: rgb(255, 102, 0);
  }

  75% {
    color: rgb(128, 51, 0);
  }

  100% {
    color: rgb(238, 106, 18);
  }
}

@keyframes changeColor-2 {
  0% {
    color: rgb(238, 106, 18);
  }

  25% {
    color: rgb(71, 33, 33);
  }

  50% {
    color: rgb(128, 51, 0);
  }

  75% {
    color: rgb(173, 96, 24);
  }

  100% {
    color: rgb(247, 58, 0);
  }
}

.text-animation {
  animation: changeColor 5s infinite;
}

.text-animation-2 {
  animation: changeColor-2 5s infinite;
}